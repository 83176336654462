import React from 'react';
import MainLayout from '../layout/MainLayout';
import _Game from '../components/_Game';

const GamePage = () => {
    return (
        <MainLayout>
            <_Game />
        </MainLayout>
    )
}

export default GamePage