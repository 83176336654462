import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const _Bottombar = () => {


  return (
    <div className='position-fixed w-100 bottom-0 myBgDark222 rounded-top-4 pt-2' expand="lg" style={{ zIndex: "102", height: "65px", boxShadow: "0px -1px 2px #444" }}>
      <div className='d-flex justify-content-center'>
        <div className='w-100 myMaxW400 text-light d-flex justify-content-around align-items-center h-100 px-4' style={{ fontSize: "1.4rem" }}>

          <div className={`${window.location.pathname === "/announcements" ? "bottomBorderActive" : "bottomBorder"}`} >
            <Link className='noLinkStyle position-relative' to={"/"}>
              <i className="bi  bi-house-fill"></i>
            </Link>
          </div>
          <div className={`${window.location.pathname === "/chat" ? "bottomBorderActive" : "bottomBorder"}`} >
            <Link className='noLinkStyle position-relative' to={"/"}>
              <i className="bi bi-trophy-fill"></i>
            </Link>
          </div>
          <div className={`${window.location.pathname === "/" ? "bottomBorderActive" : "bottomBorder"}`} >
            <Link className='noLinkStyle' to={"/"}>
              <i className="bi bi-heart-fill"></i>
            </Link>
          </div>
          <div className={`${window.location.pathname === "/" ? "bottomBorderActive" : "bottomBorder"}`} >
            <Link className='noLinkStyle' to={"/"}>
              <i className="bi bi-person-circle"></i>
            </Link>
          </div>

        </div>
      </div>
    </div>
  )
}

export default _Bottombar