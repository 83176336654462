import React from 'react';
import MainLayout from '../layout/MainLayout';
import _Home from '../components/_Home';

const _HomePage = () => {
    return (
        <MainLayout>
            <_Home />
        </MainLayout>
    )
}

export default _HomePage