import React from 'react';
import { Link } from 'react-router-dom';

const _Home = () => {

    const imgStyle = {
        width: "150px",
        height: "150px",
        backgroundSize: "cover",
        backgroundPosition: "center"

    }
    return (
        <div className='text-light pt-5 pb-5'>
            <h1 className='fw-light text-center mb-4'>Join the Game</h1>

            <div className='d-flex flex-wrap justify-content-center'>
                {
                    [...Array(5)].map((el, index) => {
                        return (
                            <div className='myBgDark222 m-1 rounded-5 myOverflowHidden myBorderDark'>
                                <div className='myMaxW200 d-flex align-items-center justify-content-between flex-column' style={{ ...imgStyle, backgroundImage: `url(/assets/images/${index}.png)` }}>
                                    <div className='d-flex gap-2 w-100 justify-content-center p-1 myBgDarkTrasparent7' >
                                        <i className="bi bi-person-fill"> 75</i>
                                        <i className="bi bi-trophy-fill"> 3</i>
                                        <i className="bi bi-stopwatch-fill"> 72h</i>
                                    </div>
                                    <Link className='noLinkStyle' to={`/competition/${index}`}><div className='px-3 border mb-2 bg-warning text-dark rounded-5 fw-bold'>JOIN</div></Link>
                                </div>

                            </div>
                        )
                    })
                }
            </div>
            <div className='pb-5'></div>
        </div>
    )
}

export default _Home