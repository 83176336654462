import React from 'react';
import MainLayout from '../layout/MainLayout';
import _Competition from '../components/_Competition';

const CompetitionPage = () => {
    return (
        <MainLayout>
            <_Competition />
        </MainLayout>
    )
}

export default CompetitionPage