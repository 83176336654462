import React from 'react';
import _Bottombar from '../components/_Bottombar';



const MainLayout = ({ children }) => {

    return (
        <div className='myBgBlack'>
            <main style={{ minHeight: "100vh" }}>
                {children}
            </main>
            {
                window.location.pathname === "/game" ?
                    null
                    :
                    <_Bottombar />
            }
        </div>
    )
}

export default MainLayout