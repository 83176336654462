import React from 'react';
import MainLayout from '../layout/MainLayout';
import _Error from '../components/_Error';

const _ErrorPage = () => {
    return (
        <MainLayout>
            <_Error />
        </MainLayout>
    )
}

export default _ErrorPage