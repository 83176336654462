import React, { useEffect, useState } from 'react'

const _Game = () => {

    const gameDeksStyle = {
        height: "calc(100vh - 65px)"
    }
    const [width, setWiidth] = useState(window.innerWidth);
    const [cardDeskWidth, setCardDeskWidth] = useState("100px");
    const [cardPlayerWidth, setCardPlayerWidth] = useState("100px");
    const [deskHand, setDeskHand] = useState([]);
    const [playerHand, setPlayerHand] = useState([]);
    const [scoreDesk, setScoreDesk] = useState(0);
    const [scorePlayer, setScorePlayer] = useState(0);
    const [youLost, setYouLost] = useState(false);
    const [endGame, setEndGame] = useState(false);

    /* Funzioni di estrazione */
    const extrDesk = () => {
        const deskHand = [];
        let score = 0;
        [...Array(2)].map(() => {

            let rand = Math.ceil(Math.random() * 13);
            deskHand.push(rand);

            if (rand == 11 || rand == 12 || rand == 13) {
                score += 10
            } else {
                score += rand
            }
        });
        setScoreDesk(score);
        setDeskHand(deskHand)
    };

    const extrPlayer = () => {
        const playerHand = [];
        let score = 0;
        [...Array(2)].map(() => {

            let rand = Math.ceil(Math.random() * 13);
            playerHand.push(rand);

            if (rand == 11 || rand == 12 || rand == 13) {
                score += 10
            } else {
                score += rand
            }
        });
        setScorePlayer(score);
        setPlayerHand(playerHand)
    };

    /* Funzioni di Fishing */
    const fishingDesk = () => {
        let rand = Math.ceil(Math.random() * 13);
        setDeskHand([...deskHand, rand]);
        setScoreDesk(scoreDesk + rand);
        return scoreDesk + rand
    }
    const fishingPlayer = () => {
        let rand = Math.ceil(Math.random() * 13);
        setPlayerHand([...playerHand, rand]);
        setScorePlayer(scorePlayer + rand)
        if ((scorePlayer + rand) > 21) { setYouLost(true); setEndGame(true) };

    }

    /* Funzione Done */
    const done = () => {

        let deskNewScore = scoreDesk;
        if (scorePlayer > deskNewScore && deskNewScore < 16) {
            let rand = Math.ceil(Math.random() * 13);
            deskNewScore += rand;
            const newDeskCards = [rand];
            [...Array(5)].map((index) => {
                if (deskNewScore < scorePlayer && deskNewScore < 16) {
                    let newRand = Math.ceil(Math.random() * 13);
                    newDeskCards.push(newRand);
                    deskNewScore += newRand;
                }
            });
            setDeskHand([...deskHand, ...newDeskCards]);
            setScoreDesk(deskNewScore);

        }

        if (scorePlayer <= deskNewScore && deskNewScore <= 21) {
            setYouLost(true)
        };
        setEndGame(true)
    }

    useEffect(() => {
        extrDesk();
        extrPlayer();
    }, [])

    useEffect(() => {
        setCardPlayerWidth(`${(width - 110) / playerHand.length}px`);
        setCardDeskWidth(`${(width - 110) / deskHand.length}px`);
    }, [playerHand, deskHand])

    return (
        <div className='position-relative' style={gameDeksStyle}>
            {
                endGame &&
                <div className='position-absolute h-100 w-100 d-flex align-items-center justify-content-center myBgDarkTrasparent7'>
                    <div className='bg-warning  w-75 display-3 p-3 text-center pt-4 shadow rounded-5 fw-normal '>
                        <div className='mb-3' style={{ fontSize: "1.5rem" }}>Your Score: <span className='fw-bold'>{scorePlayer > 21 ? "(Bust it!)" : scorePlayer}</span></div>
                        <div className='mb-3' style={{ fontSize: "1.5rem" }}>Desk: <span >{scoreDesk > 21 ? "(Bust it!)" : scoreDesk}</span></div>

                        <div >
                            {
                                youLost ?
                                    <div>
                                        <i className="bi bi-emoji-frown-fill pb-2"></i>
                                        <div>YOU LOST!</div>
                                    </div>
                                    :
                                    <div>
                                        <i className="bi bi-trophy-fill pb-2"></i>
                                        <div>YOU WON!!</div>
                                    </div>
                            }
                            <div className='bg-light rounded-5 py-2 mt-4 display-6 fw-normal text-secondary' onClick={() => window.location.reload()}>Try again</div>
                        </div>

                    </div>
                </div>
            }

            <div className='myBgDark222 text-light d-flex align-items-center justify-content-center' style={{ height: "35vh" }}>
                {
                    youLost || endGame ?
                        <div className='d-flex justify-content-center flex-wrap'>
                            {
                                deskHand.map((el) => {
                                    return (
                                        <img className='me-1 mb-1' style={{ width: cardDeskWidth, maxWidth: "200px" }} src={require(`../assets/cards/${el}_c.png`)} alt="" />
                                    )
                                })
                            }
                        </div>
                        : <div className='d-flex justify-content-center flex-wrap'>
                            {
                                deskHand.map(() => {
                                    return (
                                        <img className='me-1 mb-1' style={{ width: cardDeskWidth, maxWidth: "200px" }} src={require(`../assets/cards/b_blue.png`)} alt="" />
                                    )
                                })
                            }
                        </div>
                }

            </div>

            <div className='w-100 text-light d-flex justify-content-between flex-column pb-4' style={{ height: "50vh" }}>

                <div className='p-2 px-3 my-2'>
                    Hand Score: <span className='bg-light ms-2 border px-2 text-dark fw-bold rounded-5'>{scorePlayer}</span>
                </div>

                <div className='d-flex justify-content-center flex-wrap'>
                    {
                        playerHand.map((el) => {
                            return (
                                <img className='me-1 mb-1' style={{ width: cardPlayerWidth, maxWidth: "200px" }} src={require(`../assets/cards/${el}_c.png`)} alt="" />
                            )
                        })
                    }
                </div>

                <div className='d-flex justify-content-center gap-3'>
                    <div className='bg-light text-dark px-3 py-1 rounded-5 fw-bold' onClick={() => { fishingPlayer() }}><i className="bi bi-plus-lg"></i> New Card</div>
                    <div className='bg-warning text-dark px-3 py-1 rounded-5 fw-bold' onClick={done}>Done <i className="bi bi-check-lg"></i></div>
                </div>
            </div>

        </div>
    )
}

export default _Game