import React, { useState } from 'react'

const PricesCard = ({ elem }) => {

    const [width, setWidth] = useState(window.innerWidth);

    const imgStyle = {
        width: Math.floor(width * 0.95),
        maxWidth: "700px",
        backgroundSize: "cover",
        backgroundPosition: "center"

    }


    return (
        <div className='myMaxW700 d-flex flex-column align-items-center'>
            <h1 className={`text-light text-center prizeOverlay${elem.media.split("$")[1]} py-2 w-100`}><i className="bi bi-trophy-fill"></i> {elem.media.split("$")[1]} price</h1>
            <div className='px-3'>
            <img style={{ ...imgStyle }} src={`../assets/images/${elem.media}.png`} alt="" />
            <h2 className='mt-4 fw-normal'>{elem.productName}</h2>
            <h5 className='fw-light my-4'>{elem.description}</h5>
            <ul>
                {
                    elem.techDetail.split("£").map((el) => {
                        return <li><h5>{el}</h5></li>
                    })
                }
            </ul>
            </div>
        </div>
    )
}

export default PricesCard