import React from 'react'

const _Error = () => {
  return (
    <div className='text-light'>
      <h1 className='pt-5 text-center'>Error 404</h1>
    </div>
  )
}

export default _Error