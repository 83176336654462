import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import ranking from '../assets/JSON/ranking.json';
import { Link } from 'react-router-dom';
import prices from '../assets/JSON/prices.json';
import PricesCard from './PricesCard';


const _Competition = () => {
    const { id } = useParams();
    const [width, setWiidth] = useState(window.innerWidth);
    const [priceSelection, setPriceSelection] = useState(1);
    const imgHeight =
        width > 400 ?
            width > 700 ?
                width > 1000 ?
                    "400px"
                    : "330px"
                : "280px"
            : "200px";
    const scrollRef = useRef();

    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, []);

    const imgStyle = {
        height: imgHeight,
        backgroundSize: "cover",
        backgroundPosition: "center"
    }

    const imgStyle2 = {
        height: "150px",
        width: "150px",
        backgroundSize: "cover",
        backgroundPosition: "center"
    }

    return (
        <div className='text-light'>
            <div className='w-100 position-relative' style={{ ...imgStyle, backgroundImage: `url(/assets/images/${id}.png)` }} ref={scrollRef}>
                <div className='position-absolute myBgDarkTrasparent7 h-100 w-100 d-flex align-items-center flex-column gap-3 justify-content-end pb-5'>
                    <div className='d-flex myMaxW400 justify-content-center gap-5 display-6'>
                        <i className="bi bi-person-fill"> 75</i>
                        <i className="bi bi-trophy-fill"> 3</i>
                        <i className="bi bi-stopwatch-fill"> 72h</i>
                    </div>
                    <Link className='noLinkStyle' to={`/game`}><div className='px-4 py-1 border mb-2 bg-warning text-dark rounded-5 fw-bold display-6'>PLAY <i className="bi bi-suit-spade-fill"></i></div></Link>
                </div>
            </div>

            <div className='position-relative'>
                <div className='d-flex justify-content-center gap-1 flex-wrap position-absolute w-100' style={{ top: "-25px" }}>
                    {
                        [...Array(3)].map((el, index) => {
                            return <div className='rounded-5 border border-secondary myCursor position-relative' onClick={() => setPriceSelection(index + 1)} style={{ ...imgStyle2, backgroundImage: `url(/assets/images/${id}$${index + 1}.png)` }}>
                                <div className={`position-absolute rounded-5 prizeOverlay${index + 1} d-flex align-items-center justify-content-center display-1 fw-bold`} style={imgStyle2}>{index + 1}°</div>
                                {el}
                            </div>
                        })
                    }
                </div>
                <div className='d-flex justify-content-center gap-1 flex-wrap '>{/* spacer */}
                    {
                        [...Array(3)].map(() => {
                            return <div className='rounded-5' style={{ ...imgStyle2 }}></div>
                        })
                    }
                </div>
                <h3 className='px-3 text-center fw-normal myLightGrayColor'>The top 3 of the ranking will receive the grand prize.</h3>
            </div>

            <div className='d-flex flex-column align-items-center py-4'>
                {
                    prices && prices.map((el) => {
                        if (el.media.split("$")[0] === id && +el.media.split("$")[1] === priceSelection) {
                            return <PricesCard elem={el} />
                        }
                    })
                }
            </div>

            <div className='p-3 pt-5 pb-5'>
                <h2 className='myLightGrayColor text-center'>Ranking</h2>
                <div className='d-flex justify-content-between px-4'>
                    <h6 className='fw-light'>name</h6>
                    <h6 className='fw-light'>score</h6>
                </div>
                <hr className='mt-1' />
                <ol>
                    {
                        ranking.map((el, index) => {
                            return (
                                <li className='pe-4'>
                                    <div className={`${index === 0 ? "text-warning fw-bold" : index === 1 ? "text-secondary" : index === 2 ? "myBronzeColor" : ""} d-flex justify-content-between`}>
                                        <div>{el.name} {index <= 2 ? <i className="bi bi-trophy-fill"></i> : null}</div>
                                        <div>{el.score}</div>
                                    </div>
                                    <hr />
                                </li>

                            )
                        })
                    }
                </ol>
            </div>
            <div className='pb-5'></div>
        </div>
    )
}

export default _Competition